/* Square Market @font-face
// Copied from @square/glass-ui
// ========================================================================================= */
$sqmarket-font-directory: "./theme/fonts";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer component {
  .decoration-no-skip-ink {
    text-decoration-skip-ink: none;
  }
  .sq-input-shadow {
    box-shadow:
      0px 1px 8px rgba(0, 0, 0, 0.08),
      0px 2px 4px rgba(0, 0, 0, 0.08),
      0px 0px 2px rgba(0, 0, 0, 0.08);
  }
}

@import url("https://square-fonts-production-f.squarecdn.com/square-sans.min.css");

@mixin font-face($family, $weight, $style, $filename-woff2, $filename-woff) {
  @font-face {
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
    src: url("#{$sqmarket-font-directory}/#{$filename-woff2}") format("woff2"), url("#{$sqmarket-font-directory}/#{$filename-woff}") format("woff");
  }
}

@include font-face("Square Market", 300, normal, "sqmarket-light.woff2", "sqmarket-light.woff");
@include font-face("Square Market", 300, italic, "sqmarket-light-italic.woff2", "sqmarket-light-italic.woff");
@include font-face("Square Market", 400, normal, "sqmarket-regular.woff2", "sqmarket-regular.woff");
@include font-face("Square Market", 400, italic, "sqmarket-regular-italic.woff2", "sqmarket-regular-italic.woff");
@include font-face("Square Market", 500, normal, "sqmarket-medium.woff2", "sqmarket-medium.woff");
@include font-face("Square Market", 500, italic, "sqmarket-medium-italic.woff2", "sqmarket-medium-italic.woff");

// body global
body, html {
  font-family: var(--square-sans-text);
}

body {
  margin: 0;
}

#card-container {
  margin-bottom: 8px;
}
